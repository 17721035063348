<template>
  <section class="my-xl md:px-xl">
    <NuxtLink :to="link.href">
      <NuxtPicture
        v-if="mobileImage"
        :src="mobileImage"
        quality="80"
        :alt="altTag"
        loading="lazy"
        :img-attrs="{
          class: 'block md:hidden mx-auto',
        }"
        sizes="1vw"
      />
      <NuxtPicture
        v-if="desktopImage"
        :src="desktopImage"
        quality="85"
        :alt="altTag"
        loading="lazy"
        :img-attrs="{
          class: 'hidden md:block mx-auto',
        }"
        sizes="1vw"
      />
    </NuxtLink>
  </section>
</template>

<script setup lang="ts">
import type { ContainerItem, Page } from "@bloomreach/spa-sdk";
import { useImageContainer } from "../../composables/UseContent/useImageContainer";

const props = defineProps<{ component: ContainerItem; page: Page }>();
const { component, page } = toRefs(props);

const container = computed(() => useImageContainer(page.value, component.value));

const desktopImage = computed(() => container.value.desktopImage);
const mobileImage = computed(() => container.value.mobileImage);
const altTag = computed(() => container.value.altTag);
const link = computed(() => container.value.link);
</script>
